import React from 'react';
import './with-spinner.styles.scss'
const WithSpinner = () =>{
       return(<div className='spinner-overlay'>
               <div className='spinner-container'/>
           </div>)


    };

export default WithSpinner;