import before from './assets/before.PNG';
import after from './assets/after.PNG';
import cover from './assets/cover.png'
const service_list = {
      cleansing_care: {
          url: "cleansing_care",
          title: "Лазерная шлифовка кожи",
          used_for: ["Неровность рельефа кожи",
              "Плотность и пористость кожи",
              "Все виды морщин",
              "Тусклый, серый цвет лица",
              "Гусиные лапки",
              "Нечеткий овал лица",
              "Шрамы", "Рубцы постакне"],
          description: "FT лазерная шлифовка – эффективный метод " +
              "выравнивания и омоложения кожи с длительным сохранением" +
              " достигнутого результата. Это оптимальный метод для тех, " +
              "кто хочет значительно омолодить кожу лица, не прибегая к " +
              "помощи пластического хирурга. Процедура лазерной шлифовки" +
              " проводится при точном контроле глубины воздействия лазерного луча, " +
              "что позволяет свести к нулю риск осложнений.brFT лазерная шлифовка" +
              " позволяет справиться с такими проблемами, как: внешние признаки старения, неровный кожный рельеф, кожные новообразования, то есть со всеми эстетическими проблемами, которые доставляют женщине массу проблем и хлопот.brКроме того, процедура FT лазерной шлифовки кожи является эффективным способом сглаживания и удаления рубцов, шрамов, стрий и растяжек Она позволяет значительно выровнять рельеф кожи и сделать ее максимально гладкой.",
          additional: "Омоложение: процедура лазерной шлифовки лица заключается в испарении лазерным лучом поверхностных слоев кожи и глубоком прогреве тканей с целью достижения выравнивания рельефа и подтяжки кожи лица. В ходе лазерной шлифовки старые ороговевшие клетки удаляются, активируется синтез новых коллагеновых и эластиновых волокон, в итоге кожа разглаживается, морщины исчезают, улучшаются оптические свойства кожи, а за счет глубокого прогрева тканей достигается выраженный эффект лифтинга.brУдаление рубцов, шрамов: во время лазерной шлифовки рубцов и шрамов происходит испарение поверхностных слоев кожи в области шрама, в результате чего кожа разглаживается, а рубцы и шрамы становятся практически незаметными.brУдаление растяжек (стрий): в ходе процедуры лазерной шлифовки растяжек лазерный луч буквально по клеточке испаряет соединительную ткань, образующую растяжки, одновременно запуская процессы, стимулирующие образование новой, более гладкой кожи. В результате процедур FT лазерной шлифовки растяжки становятся незаметными для глаза.",
          pictures: [{before: "chistka_before.jpg", after: "chistka_after.jpg"}],
          recovery: "в период восстановления (7-14 дней) после лазерной шлифовки кожа выглядит как" +
              " будто загорелой, после чего покрывается корочкой, которая отшелушивается в течение 1-2 недель.",
          course: "Разовая процедура",
          effect_length: "1-3 года",
          price: null,
          priceArea: [
              {
                  area: "Шлифовка морщин",
                  price: "от 1000р за 1 кв.см."
              }, {
                  area: "Шлифовка рубцов постакне",
                  price: "от 500р за 1 кв.см."
              }, ]
      },
      laser_gynecology: {
          url: "laser_gynecology",
          title: "Лазерная гинекология",
          cover: cover,
          used_for: [ "Лечение недержания мочи стрессорного и смешанного типов",
                     "Лечение cystocele I-IV степени","Профилактика пролапса гениталий в перименопаузальном периоде",
          "Лечение пролапса гениталий различной степени в виде самостоятельных технологий и в качестве подготовки к оперативному лечению, а также после него для усиления эффекта",

          "Лечение синдрома релаксации влагалища молодых пациенток в послеродовом периоде с целью восстановления естественного объема влагалища и эластичности его стенок",

          "Лечение синдрома релаксации влагалища, возникающего вследствие изменения уровня естественного гормонального фона в период перименопаузы",

          "Лечение различных форм сексуальных дисфункций, связанных с изменением структуры влагалищной стенки",

          "Лечение атрофии слизистой половых органов как альтернативный метод гормонозаместительной терапии. Улучшение структуры рубцовых изменений стенки влагалища после оперативных и иных видов вмешательств. Устранение напряжения и натяжения рубцовой ткани"],
          description: "Технология IncontiLase – процедура, направленная на обработку передней стенки и преддверия влагалища для лечения различных форм недержания мочи.br" +
              "br" +
              "Технология IntimaLase – процедура, направленная на круговую обработку стенок и преддверия влагалища. Данная процедура предназначена для лечения синдромов вагинальной релаксации и «большого» влагалища, лечения пролапса гениталий и цистоуретральной атрофии слизистой в перименопаузальном периоде, лечения различных форм сексуальных дисфункций.br" +
              "br" +
              "В результате применения технологий IncontiLase и IntimaLase обработанные лазером ткани обогащаются новым коллагеном, подтягиваются и становятся более эластичными. Сокращение тканей обеспечивает улучшенную поддержку мочеиспускательного канала, уменьшает гипермобильность уретры, устраняя таким образом, симптомы недержания мочи.br" +
              "br" +
              "Происходит увеличение плотности и эластичности стенок влагалища, уменьшение объема влагалища, восстанавливается опорная функция пубовисцеральной фасции тазового дна, связочного аппарата, возрастает сила и длительность сокращения мышц тазового дна. Тепловое воздействие обеспечивает восстановление нейрогуморальной регуляции слизистой влагалища, что приводит к устранению симптомов атрофии.br" +
              "br" +
              "Эффективность и безопасность технологий подтверждена данными гистологических исследований биоптатов слизистой влагалища, данными УЗИ и МРТ органов малого таза, эластографией, перенеометрией, исследованиями уродинамики, данными иммуногистохимических исследований.",
          additional: "Ознакомиться с процедурой можно здесь br https://www.youtube.com/watch?v=su6XrRclmnk",
          pictures: [[before, after], [before, after]],
          recovery: null,
          course: "1-2 процедуры",
          effect_length: "От года и более",
          price: "15000р",
          priceArea: null
      },
      ft_scar_removal: {
          url: "ft_scar_removal",
          title: "FT лазерная шлифовка рубцовой ткани на лазером аппарате Fotona",
          cover: cover,
          used_for: ["Удаление шрамов", "Удаление рубцов постакне"],
          description: "FT лазерная шлифовка рубцовой ткани – методика послойного удаления рубцовой ткани на управляемой глубине, основанная на технологии «холодной» абляции. Методика применяется для шлифовки гипертрофических рубцов до уровня плоскости кожи, нормотрофических рубцов для создания оптически ровной поверхности (маскирующая процедура), гипотрофических рубцов для выравнивания дна рубца, удаления краев в местах перелома луча света (маскирующая процедура).",
          additional: "FT лазерная шлифовка рубцов предназначена для работы с любыми видами рубцовой ткани. Применение лазерной технологии «холодной» абляции позволяет избежать перегрева основания рубца с последующим термонекрозом ложа, что является основным осложнением стандартных методик лазерной шлифовки.",
          pictures: [{before:"rubcovaya_tkan_before.jpg", after: "rubcovaya_tkan_after.jpg"}],
          recovery: "От 5 до 10 дней",
          course:  "От 1 процедуры",
          effect_length: "Перманентный",
          price: "от 500р за 1 кв.см."
      },
      fotona4D_rejuvination: {
        url: "fotona4D_rejuvination",
        title: "FT лазерная шлифовка рубцовой ткани на лазером аппарате Fotona",
        cover: cover,
        used_for: ["Удаление шрамов", "Удаление рубцов постакне"],
        description: "FT лазерная шлифовка рубцовой ткани – методика послойного удаления рубцовой ткани на управляемой глубине, основанная на технологии «холодной» абляции. Методика применяется для шлифовки гипертрофических рубцов до уровня плоскости кожи, нормотрофических рубцов для создания оптически ровной поверхности (маскирующая процедура), гипотрофических рубцов для выравнивания дна рубца, удаления краев в местах перелома луча света (маскирующая процедура).",
        additional: "FT лазерная шлифовка рубцов предназначена для работы с любыми видами рубцовой ткани. Применение лазерной технологии «холодной» абляции позволяет избежать перегрева основания рубца с последующим термонекрозом ложа, что является основным осложнением стандартных методик лазерной шлифовки.",
        pictures: [{before:"d4_omolozhenie_before.jpg", after:"d4_omolozhenie_after.jpg"}],
        recovery: "От 5 до 10 дней",
        course:  "От 1 процедуры",
        effect_length: "Перманентный",
        price: "от 500р за 1 кв.см."
    },
      botox_injection: {
          url: "botox_injection",
          title: "Иньекции Ботокс и Диспорт",
          cover: cover,
          used_for: ["устранение мимических морщин на лбу, вокруг глаз и губ",
              "Устраниение возрастных изменений кожи шеи и декольте","Лечение спазма век",
              "Коррекции асимметрии лица",
              "Устранениe повышенного потоотделения (уколы препарата в область подмышек, стопы, кисти рук"],
          description: "Botox и Dysport – препараты, в состав которых входит ботулотоксин, или нейротоксин группы А, в минимальной концентрации. Действие токсина заключается в предотвращении высвобождения медиатора нервного волокна. После введения препарата сокращение мимической мышцы становится невозможным, так как на нее не передается нервный импульс. Botox выпускается американским производителем Allergan, а его аналог Dysport – французской компанией Beaufour Ipsen International. Отличие препаратов состоит в концентрации токсина ботулизма: 100 единиц Ботокса равны 500 единицам Диспорта.br" +
              "Инъекции препаратов Ботокс и Диспорт блокируют мимические мышцы, из-за чего они временно перестают сокращаться. Под действием Ботокса лицо не теряет выразительности, просто во время демонстрации эмоций на коже не образуется складок. Действие процедуры сохраняется до 9 месяцев. Доза препаратов и точки их введения под кожу определяются специалистом после первичного осмотра и медицинского обследования пациента.",
          additional: "Как действует токсин ботулина?br" +
              "— Механизм действия заключается в блокировке двигательного импульса с нерва на мышцу. В результате мышца расслабляется. При этом даже если вы захотите по привычке наморщить лоб или нахмуриться, у вас  не получится — мышца останется расслабленной, а кожа гладкой.br" +
              "От Ботокса, Диспорта могут отмереть мышцы?br" +
              "— Мышечные волокна не разрушаются, мышца просто «спит», так как прерывается нервно-мышечная передача к мимической мышце. Эта передача восстанавливается через некоторое время.br" +
              "Когда становится заметен эффект после процедуры и долго ли он сохраняется?br" +
              "— Препарат начинает действовать примерно через трое суток и достигает максимума через две недели. Мелкие и глубокие морщины разглаживаются, а поверхность кожи становится ровной. Эффект сохраняется от четырех до шести месяцев, в зависимости от обменных процессов, состояния кожи. Часто после нескольких процедур исчезает вредная привычка хмурить лоб, и проблема решается сама собой. Для достижения стойкого и длительного эффекта рекомендуется введение препаратов 2 раза в течение года.br" +
              "br" +
              "br" +
              "Поможет ли Ботокс, Диспорт избавиться от уже сформировавшихся глубоких морщин?br" +
              "— Применение данных препаратов можно сочетать с коррекцией глубоких морщин гелями на основе гиалуроновой кислоты (Restylane, Surgiderm).br" +
              "br" +
              "Есть ли побочные эффекты?br" +
              "— Небольшие  гематомы, отеки вокруг глаз. В области лба может наблюдаться парестезия — ощущение инородного тела, зуд. Это нормально, так как является особенностью работы нервной системы.br" +
              "Есть и приятный побочный эффект — в некоторых случаях может проходить мигрень, если она была вызвана парадоксальной работой мышц. Недаром изначально препараты на основе токсина ботулина применялись для лечения мигреней и заболеваний мышц.br" +
              "Применяется ли Ботокс, Диспорт для мужчин?br" +
              "— Да, эти препараты  применяются для мужчин, как с целью коррекции морщин, так и для решения распространенной проблемы гипергидроза — повышенного потоотделения в области подмышек, на ладонях.br" +
              "В последнем случае делаются множественные подкожные инъекции. В результате снижается активность потовых желез (блокируются нервные окончания, которые подходят к потовой железе). При этом потоотделение не «отключается» полностью, терморегуляция организма сохраняется.br",
          pictures: [{before: "botox_before.jpg", after: "botox_after.jpg"}],
          recovery: null,
          course: "Разовая процедура",
          effect_length: "Действие процедуры сохраняется до 9 месяцев",
          price: null,
          priceArea: [
              {
                  area: "Межбровье",
                  price: "4650р"
              },
              {
                  area: "Лоб",
                  price: "4350р"
              },
              {
                  area: "Глаза",
                  price: "4950р"
              },]
      },
      growths_removal: {
          url: "growths_removal",
          title: "FT лазерное удаление новообразований",
          cover: cover,
          used_for: ["Удаления родинок", "Удаление бородавок", "Удаление папиллом", "Удаление атеромы",
          "Удаление точечных гемангиом","Удаление кератомы, лентиго"],
          description: "Компания Fotona (Словения) разработала уникальную, эффективную технологию – FT лазерное удаление новообразований.br" +
              "br" +
              "Процедура FT лазерного удаления новообразований позволяет бережно удалить образования абсолютно любой сложности и размера. Преимущество данного метода заключается в безопасности применения технологии FT.br" +
              "br" +
              "Во время процедуры лазерного удаления родинок, бородавок, папиллом и пр., лазерный луч воздействует очень точно и на необходимую глубину, постепенно послойно испаряя ткани. Высокоточное лазерное воздействие дает возможность удалить родинки, бородавки, папилломы, невусы, не затрагивая здоровую кожу вокруг, и свести к минимуму риск появления неэстетических рубцов и последствий. Также во время процедуры FT лазерное удаление новообразований происходит коагуляция сосудов, что позволяет избежать кровотечений и инфицирования.",
          additional: "",
          pictures: [{before:"novoobrazovaniya_before.jpg", after:"novoobrazovaniya_after.jpg"},
              {before:"novoobrazovania_before.jpg", after:"novoobrazovania_after.jpg"}],
          recovery: "От 5 дней кроме гемнагиом",
          course: "Разовая процедура",
          effect_length: "Перманентно",
          price: null,
          priceArea: [
              {
                  area: "Удаление бородавок",
                  price: "от 1000р"
              },
              {
                  area: "Удаление родинок",
                  price: "от 1500р"
              },
              {
                  area: "Удаление папиллом",
                  price: "от 300р"
              },
              {
                  area: "Удаление точечных гемангиом",
                  price: "от 300р"
              },
              {
                  area: "Удаление кератомы, лентиго",
                  price: "от 2000р"
              },]
      },
      acne_removal: {
          url: "acne_removal",
          title: "Лазерное лечение акне",
          cover: cover,
          used_for: ["Угревая сыпь",
              "Прыщи",
              "Рубцы постакне"],
          description: "Косметические средства и прием антибиотиков не могут полностью решить все проблемы, сопутствующие угревой болезни (комедоны, угри, сыпь, постоянные воспаления). Такой возможностью обладает только лазерная методика FT лечения акне, с помощью которой можно добиться уникального косметического и лечебного эффекта в борьбе с данной проблемой. Выраженный эффект виден сразу после первой процедуры. Лечение акне лазером эффективно при любых формах акне, методика является физиологичной и безопасной, при ее проведении не происходит повреждений кожного покрова.br" +
              "br" +
              "С помощью методики FT лазерного лечения акне можно не только лечить активные формы акне, но и проводить профилактические процедуры, направленные на стерилизацию кожи, а также лечить осложнения, которые, как правило, проявляются в виде рубцов постакне. Воздействие лазерного луча сглаживает рубцы, делает их более мягкими, эластичными и практически незаметными для глаза.",
          additional: "Во время процедуры FT лазерного лечения акне лазерный луч глубоко проникает в кожу и убивает болезнетворные бактерии, которые вызывают воспаление. Лазер, точно воздействуя на каждый очаг воспаления кратковременными импульсами, доводит ткани до критической температуры, при которой бактерии гибнут. Процесс происходит абсолютно безболезненно. После процедуры постепенно уходит воспаление, исчезают отеки и покраснения.br" +
              "br" +
              "Кроме того, процедуры лечения акне лазером позволяют восстанавливать обменные процессы в коже, активировать синтез новых коллагеновых волокон и отшелушивать старые ороговевшие клетки. Кожа полностью очищается от прыщей и выглядит гладкой и привлекательной.br" +
              "br" +
              "Для остановки острого воспалительного процесса достаточно двух-трех лазерных процедур, для полного выздоровления требуется более длительное лечение, продолжительность которого зависит от серьезности и длительности заболевания.",
          pictures: [{before:"acne_before.jpg", after:"acne_after.jpg"}],
          recovery: "5-7 дней",
          course: "2-3 процедуры",
          effect_length: "Индивидуально",
          price: null,
          priceArea: [
              {
                  area: "Лицо",
                  price: "от 1500р"
              },
              {
                  area: "Тело",
                  price: "от 2000р"
              },]
      },
      vessel_removal: {
          url: "vessel_removal",
          title: "Лазерное лечение сосудов",
          cover: cover,
          used_for: ["Расширенные сосуды",
"Купероз – сосудистое заболевание кожи, при котором на ней появляются неэстетичные сосудистые звездочки и сеточки расширенных капилляров",
"Розацеа – хроническое кожное заболевание, основными признаками которого является покраснение кожи и образование бугорков, гнойничков и других высыпаний на лице",
"Гемангиомы – родимые пятна, состоящие из паукообразных расширенных кровеносных сосудов кожи"],
          description: "FT лазерное удаление сосудов сочетает в себе наиболее важные характеристики, которые позволяют эффективно и безболезненно удалить сосудистые звездочки любой сложности и исключает ожоги и болевые ощущения.br" +
              "br" +
              "С помощью лазерного удаления сосудистых звездочек можно удалять сосуды как на лице, так и на теле. Размер видимого сосуда в диаметре может достигать 2,5 мм (это достаточно большие сосудистые звездочки на ногах).",
          additional: "Особенность процедуры FT лазерного удаления сосудов заключается в воздействии лазерного луча, в результате которого происходит «склеивание» стенок сосуда. Мелкие сосуды удаляются бесследно, крупные сосудистые пятна, гемангиомы, бледнеют и значительно уменьшаются в размерах. Лазерное воздействие оказывается только на стенки капилляров, окружающие ткани и здоровые сосуды не травмируются.br" +
              "br" +
              "Количество процедур лазерного удаления сосудистых звездочек зависит от диаметра сосуда. Если сосуд небольшого диаметра (1-1,5 мм), то он может исчезнуть после 1-2 процедур. При работе с более крупными сосудами может потребоваться до 4-5 визитов к врачу.br" +
              "br" +
              "Во время процедуры удаления сосудов лазером пациент практически не испытывает чувство дискомфорта, после процедуры может наблюдаться незначительное покраснение кожи, которое исчезает через 15-30 минут. После сеанса лазерного удаления сосудистых звездочек обработанный сосуд сначала меняет цвет на более темный (что свидетельствует о положительном результате), а через 2-3 недели полностью исчезает. При коагуляции больших сосудов могут наблюдаться небольшие корочки или незначительное шелушение кожи.",
          pictures: [{before:"sosudy_before.jpg", after:"sosudy_after.jpg"}],
          recovery: null,
          course: "1,2 мм – 1-2 процедуры, более крупные (до 2,5 мм) – 4-5 процедур.",
          effect_length: "В течение 2-3 недель сосуд полностью исчезает",
          price: null,
          priceArea: [
              {
                  area: "до 2 кв.см.",
                  price: "от 2000р"
              },
              {
                  area: "от 2-х до 5 кв.см",
                  price: "от 3800р"
              },]
      },
      pigmentation_removal: {
          url: "pigmentation_removal",
          title: "Лазерное удаление пигментации",
          cover: cover,
          used_for: ["Пигментированные рубцы",
          "Веснушки",
          "Возрастные пигментные пятна",
          "Себорейный кератоз",
          "«Солнечные» пятна",
          "Эпидермальные родинки",
          "Травматическая пигментации"],
          description: "игментные пятна возникают в результате избыточного скопления меланина, который окрашивает кожу в желто-коричневые оттенки. Самые распространенные причины появления пигментации: старение кожи, чрезмерное пребывание на солнце или в солярии, беременность, гормональные перестройки, системные нарушения обмена веществ, различные заболевания и др.br" +
              "br" +
              "Наличие пигментации в некоторых случаях и вовсе может оказаться небезопасной для здоровья: при выявлении рака кожи пигмент может затруднить его обнаружение.br" +
              "br" +
              "Для удаления пигментных пятен, как правило, используют криотерапию, кислотное вымывание, отбеливание, фототерапию, низкоэнергетическую импульсную терапию и др. Зачастую эти методы не обеспечивают желаемого результата и эффект от лечения держится недолго. На сегодняшний день самой эффективной технологией бесследного удаления пигментации любой этимологии, сложности и размера является FT лазерное удаление пигментации.",
          additional: "FT лазерное удаление пигментации – новейшая лазерная технология, которая является быстрым, комфортным, безопасным и эффективным методом лечения гиперпигментации. Уже через неделю после FT лазерного удаления пигментации вы сможете увидеть долгожданный результат – полное исчезновение пигментных пятен на своей коже.br" +
              "br" +
              "Специалисты индивидуально для каждого пациента подберут удобное и комфортное лечение." +
              "br" +
              "Процедура FT лазерного удаление пигментации заключается в воздействии лазерного луча на частицы меланина и послойном разрушении пигмента. После лазерного воздействия на коже, в обработанной зоне, образуются молодые клетки, которые синтезируют и накапливают нужное количество пигмента, от чего цвет обработанного участка меняется на более светлый. В результате на месте пигментного пятна образуется чистая и здоровая кожа. Процедура проводится без анестезии и не имеет побочных эффектов.",
          pictures:  [{before:"pigmintation_before.jpg", after:"pigminatation_after.jpg"}],
          recovery: "2 недели. В эти дни возможен небольшой отек, который проходит за 2 дня. В течение 5-7 дней на коже образуется тонкая корочка, которая заживает без образования рубцов",
          course: "от 3 и более процедур с интервалом в 3-4 недели",
          effect_length: "Перманентно",
          price: null,
          priceArea: [
              {
                  area: "до 2 кв.см.",
                  price: "от 2000р"
              },
              {
                  area: "от 2-х до 5 кв.см",
                  price: "от 3800р"
              },]
      },
      laser_epilation: {
          url: "laser_epilation",
          title: "Лазерная эпиляция",
          cover: cover,
          used_for: [ "Повышенный рост волос на лице и теле"],
          description: "Лазерная эпиляция – это самый эффективный способ удаления волос навсегда как для женщин так и для мужчин. Благодаря световой энергии лазера, которая воздействуя на пигмент волоса и на сосуд, который его питает, трансформируется в тепловую энергию и неотвратимо разрушает волосяной фолликул. Таким образом, мы можем провести удаление волос лазером на всех зонах лица и тела.br" +
              "br" +
              "Этот вид удаления нежелательных волос подходит практически всем, но от тех или иных особенностей фототипов кожи и степени пигментации волос зависит выбор того или иного лазера.br" +
              "br" +
              "В нашей клинике, для решения всех задач по эпилированию, имеются два мощнейших лазера – Александритовый и Неодимовый для работы по коже I-IV фототипа. Решение о применении того или иного лазера принимает доктор на консультации, после того как определит, воздействие какого лазера будет наиболее эффективно для данного типа кожи и волос.br" +
              "br" +
              "Для полного лазерного удаления волос, Вам понадобится несколько процедур лазерной эпиляции с перерывами между процедурами в 1.5-3 месяца в начале лечения.br" +
              "br" +
              "Часто, пациенты клиники задают вопрос - сколько понадобиться процедур лазерной эпиляции, чтобы удалить нежелательные волосы навсегда? В среднем, для получения хорошего результата лечения, нужно 5-8 сеансов эпиляции. Но в каждом индивидуальном случае, невозможно рассчитать гарантированное количество процедур, за которые волосы должны непременно уйти, ведь никто не может точно сказать, сколько фолликулов у вас в коже, сколько процентов волос у вас находится в активной фазе роста и соответственно, сколько процедур удаления нежелательных волос лазером понадобится именно Вам! Лазерная эпиляция для мужчин может предпологать под собой большее количество процедур, чем для женщин.",
          additional: "Важно понимать цикл роста волос и то, как этот цикл изменяется на различных анатомических участках. Ниже описаны три стадии роста волос:br" +
              "br" +
              "Анаген: стадия синтеза волоса. Стадия активного роста, во время которой волосяная луковица остается неповрежденной. Волос растет как вверх, так и вниз. На ранней стадии анагена луковица находится ближе всего к поверхности кожи, таким образом обеспечивая наиболее эффективную обработку. Длительность стадии измеряется в месяцах/годах.br" +
              "Катаген: краткосрочная промежуточная стадия, измеряется неделями, является переходной от анагена к телогену. Во время этой стадии нижняя треть волосяного фолликула поглощается телом.br" +
              "Телоген: период покоя. Волосяной луковицы уже нет. Именно во время этой стадии волос выпадает или выталкивается фолликулом нового волоса в стадии анагена. Длительность стадии измеряется в месяцах/годах.br" +
              "Только во время стадии роста или анагена волос реагирует на лазерное излучение. Для достижения долгосрочных результатов по удалению волос, лазерный свет должен проникнуть в глубину волосяного фолликула, находящегося в стадии анагена.br"+
             "Наиболее часто задаваемые вопросы: br" +
               "Что такое лазерная эпиляция?br" +
              "Это устранение волосяных фолликул без повреждения кожи. Благодаря воздействию лазера на волосяной фолликул обработанный волос разрушается, выпадает и больше не вырастает  никогда. Лазерная эпиляция —методика, позволяющая  решить проблему  нежелательных волос  на лице и теле  надолго или навсегда. В мире эта технология существует и успешно применяется на практике более 20 лет. br" +
              "br" +
              "Правда ли что лазерная эпиляция может нанести вред здоровью, лазер плохо влияет на внутренние органы?br" +
              "Глубина проникновения лазерного луча при эпиляции составляет всего 1−4 мм, он достигает только волосяных фолликулов, затем полностью рассеивается и не проникает глубже кожи. При воздействии лазера разрушается только волосяная луковица. Лазерный луч скользит по поверхности кожи, не повреждая ее. Единственное, что может возникнуть после процедуры — покраснение, ктоторое является показателем правильного выполнения процедуры и через несколько часов проходит бесследно.br" +
              "Может ли лазерная  эпиляция может быть причиной образования рубцов и врастания волос?br" +
              "Лазерная эпиляция не сопровождается  нарушением целостности кожных покровов, поэтому  нет и процессов рубцевания. Врастание волос, очень часто сопровождает восковую депиляцию и не встречается при лазерной эпиляции. Более того лазерный метод рекомендуют в качестве метода устранения подобных явлений.br" +
              "Насколько дорого стоит лазерная эпиляция?br" +
              "Давайте посчитаем. Сколько обычно женщина тратит в год на покупку средств для депиляции — станки, кремы, пенки и т. д. А за 10 лет? А теперь посчитайте стоимость курса лазерной эпиляции — 6-8 сеансов с интервалом 2−3 месяца, и все. Сравните эти цифры.br" +
              "Лазерная эпиляция — это больно?br" +
              "Невозможно утверждать однозначно. Все зависит от индивидуального порога болевой чувствительности. Ощущения от лазерной эпиляции скорее можно назвать неприятными, они сравнимы со щелчками по коже и обычно переносятся хорошо, при этом используется специализированная система охлаждения.br" +
              "Можно удалить волосы за один раз и навсегда?br" +
              "К сожалению, невозможно за один раз получить требуемый эффект. Лазерный свет поглощается только «зрелыми» волосяными фолликулами, а таких на нашем теле от 5 до 20%. Поэтому и требуется курс из 6-8 процедур. Разрушенные фолликулы уже в дальнейшем не растут. Необходимо также учитывать такие факторы как эндокринные нарушения, гормональный дисбаланс.br" +
              "Можно ли проводить лазерную эпиляцию на загорелой коже?br" +
              "При загаре в коже образуется меланин, который служит мишенью для лазера, и риск получения ожога возрастает. Однако в нашей клинике  есть оборудование, позволяющее при адекватном подборе программ обеспечить необходимый эффект без повреждения загорелой кожи.br" +
              "Способствует ли лазер появлению новообразований кожи?br" +
              "Опухоли кожи являются противопоказанием для лазерной эпиляции. Онкогенным действием обладают ультрафиолетовые волны, которых в лазерном луче нет. Таким образом, спровоцировать возникновение новообразований на коже можно, скорее, при посещении солярия, а не при лазерных процедурах.",
          pictures: [{before:"epilation_before.jpg", after: "epilation_after.jpg"},
              {before:"epilation2_before.jpg", after: "epilation2_after.jpg"}  ],
          recovery: null,
          course: "от 5 и более процедур с интервалом в 1-1.5 месяцев",
          effect_length: "Перманентно",
          price: null,
          priceArea: [
              {
                  area: "Эпиляция лба",
                  price: null,
                  man: "2000р",
                  woman:"1200р",
              },
              {
                  area: "Эпиляция бровей",
                  price: null,
                  man: "1500р",
                  woman:"1200р",
              },
              {
                  area: "Эпиляция межбровья",
                  price: "500р",
                  man: null,
                  woman:null,

              },
              {
                  area: "Эпиляция скул",
                  price: "1000р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция щек",
                  price: "1350",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция бакенбардов",
                  price: "1200р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция верхней губы",
                  price: "750р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция крыльев носа",
                  price: "750р",
                  man: null,
                  woman:null,

              },
              {
                  area: "Эпиляция ушей",
                  price: "1000р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция подбородка",
                  price: "1200р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция лица полностью",
                  price: "3800р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция передней/задней поверхности шеи",
                  price: "2000р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция груди полностью",
                  price: null,
                  man: "6500р",
                  woman:"2990р",

              },
              {
                  area: "Эпиляция Ареол",
                  price: "990р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция подмышечных впадин",
                  price: null,
                  man: "4000р",
                  woman:"1990р",
              },
              {
                  area: "Эпиляция рук полностью",
                  price: null,
                  man: "7990р",
                  woman:"5990р",
              },
              {
                  area: "Эпиляция рук до локтя",
                  price: "3000р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция плеча",
                  price: null,
                  man: "5000р",
                  woman:"3500р",
              },
              {
                  area: "Эпиляция пальцев на руках",
                  price: null,
                  man: "1500р",
                  woman:"1000р",
              },
              {
                  area: "Эпиляция белой линии живота",
                  price: null,
                  man: "2000р",
                  woman:"1500р",
              },
              {
                  area: "Эпиляция живота полностью",
                  price: "3500р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция спины полностью",
                  price: null,
                  man: "11990р",
                  woman:"8990р",
              },
              {
                  area: "Эпиляция поясницы",
                  price: null,
                  man: "3990р",
                  woman:"2990р",
              },
              {
                  area: "Эпиляция ягодиц",
                  price: "3990р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция бикини",
                  price: "от 2000р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция внутренней поверхности бедра",
                  price: "2500р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция ног полностью",
                  price: "11990р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция бедер",
                  price: "6990р",
                  man: null,
                  woman:null,
              },
              {
                  area: "Эпиляция голени",
                  price: "4990р",
                  man: null,
                  woman:null,
              },


          ]
      },
      laser_rejuvination: {
          url: "laser_rejuvination",
          title: "Лазерное омоложение",
          cover: cover,
          used_for: ["Нечеткий овал лица",
          "Плотность и пористость кожи",
          "Мелкие и средние морщины",
          "«Гусиные лапки»",
          "Тусклый цвет лица",
          "Фотостарение"],
          description: "Глубокое лазерное омоложение – курсовая процедура, состоящая из 4-6 сеансов, которая позволяет получить длительный эффект омоложения кожи (до 5 лет) и требует минимального периода восстановления.br" +
              "br" +
              "После курса процедур глубокого лазерного омоложения наблюдается формирование нового коллагенового каркаса, поверхность кожи становится более упругой, эластичной, улучшается ее структура, цвет, овал лица становится более четким, разглаживаются морщины.",
          additional: "Глубокое лазерное омоложение представляет собой процедуру этапного построения нового коллагенового каркаса кожи и обеспечивает видимый эффект омоложения и лифтинга кожи.",
          pictures: [{before: "frac3t3_before.jpg", after: "frac3t3_after.jpg"}],
          recovery: "В течение двух дней возможен небольшой отек, покраснение кожи и шелушение.",
          course: "4-6 процедур",
          effect_length: "до 5 лет",
          price: "10000р",
          priceArea: null
      },
      laser_piling: {
          url: "laser_piling",
          title: "Лазерный пилинг",
          cover: cover,
          used_for: ["Неровный рельеф кожи",
          "Средние и мелкие морщины",
          "Плотность и пористость кожи",
          "«Гусиные лапки»",
          "Тусклый цвет лица"],
          description: "FT лазерный пилинг – эффективный метод лазерного воздействия, направленный на обновление и выравнивание кожи. FT лазерный пилинг проводится с целью разглаживания рельефа кожи, омоложения, улучшения ее оптических и защитных свойств, устранения и сглаживания эстетических дефектов.br" +
              "br" +
              "Благодаря процедурам лазерного пилинга с поверхности кожи убираются мелкие неровности, морщины, шрамы, рубцы, отмершие клетки, очищаются поры. Кожа выглядит упругой и эластичной, улучшаются ее светоотражающие свойства, восстанавливается здоровый цвет лица.",
          additional: "FT холодный лазерный пилингbr" +
              "Описание процедуры лазерного пилинга:br" +
              "Основная цель холодного лазерного пилинга – мгновенное выравнивание поверхности кожи и достижение эффекта «бархатной кожи».br" +
              "br" +
              "Суть процедуры холодного лазерного пилинга:br" +
              "Холодный лазерный пилинг заключается в воздействии лазерного луча на кожу, который послойно испаряет ее верхние слои. После процедуры лазерного пилинга исчезают мелкие морщинки, кожа разглаживается, становиться ровной, матовой и бархатистой, значительно улучшаются ее оптические свойства.br" +
              "br" +
              "Период восстановления после лазерного пилинга:br" +
              "составляет 3- 5 дней, сопровождается шелушением кожи.br" +
              "br" +
              "Курс лазерного пилинга:br" +
              "3 процедуры.br" +
              "br" +
              "Эффект после холодного FT лазерного пилинга:br" +
              "мгновенный эффект, сохраняется до полугода.br" +
              "br" +
              "FT горячий лазерный пилингbr" +
              "Описание процедуры лазерного пилинга:br" +
              "FT горячий лазерный пилинг также направлен на выравнивание рельефа, но, в отличие от холодного лазерного пилинга, данная процедура обеспечивает еще и заметную подтяжку кожи. Результат горячего лазерного пилинга лица не проявляется моментально, а нарастает в течение 2-3 месяцев. В конечном итоге эффект от процедуры горячего лазерного пилинга более выражен и дольше сохраняется.br" +
              "br" +
              "Суть процедуры горячего FT лазерного пилинга:br" +
              "Концепция FT горячего лазерного пилинга заключается в воздействии лазерного луча, который послойно испаряет поверхностный слой эпидермиса и прогревает верхние слои дермы (поэтому процедура и называется горячий лазерный пилинг). Более глубокое лазерное воздействие и прогрев тканей вызывают видимое сокращение площади кожного лоскута (эффект лифтинга), активацию процессов обновления и омоложения кожи, выравнивание кожного рельефа.br" +
              "br" +
              "Кожа становится более ровной, упругой, бархатистой и подтянутой, а овал лица более четким, мелкие морщины исчезают, а глубокие сглаживаются. Кожа сияет, становится более здоровой и молодой.br" +
              "br" +
              "Период восстановления после горячего FT лазерного пилинга:br" +
              "br" +
              "составляет 5-7 дней, сопровождается шелушением, отеком, покраснением кожи.br" +
              "br" +
              "Курс процедур лазерного пилинга:br" +
              "1-3 процедуры.br" +
              "br" +
              "Эффект после горячего FT лазерного пилинга:br" +
              "нарастает в течение 2-3 месяцев и сохраняется до года.",
          pictures: [{before: "piling_before.jpg", after: "piling_after.jpg"}],
          recovery: "5-7 дней",
          course: "1-3 процедуры",
          effect_length: "до года для горячего FT пилинга/ до полугода для холодного FT пилинга",
          price: "45000р",
          priceArea: [
              {
              area: "Холодный пилинг лица.тела",
              price: "от 4000р"
              },
              {
                  area: "Горячий пилинг лица",
                  price: "от 7000р"
              }]
      },
      laser_giarunoplastic: {
          url: "laser_giarunoplastic",
          title: "Лазерная гиалуронопластика",
          cover: cover,
          used_for: ["Обезвоживание кожи",
          "Мелкоморщинистый тип старения кожи",
          "Купероз"],
          description: "Лазерная гиалуронопластика кожи (атермический лазерный лифтинг) позволяет многократно повышать содержание гиалуроновой кислоты в коже: под воздействием лазерного излучения происходит открытие транспортных каналов и активное проникновение через них низкомолекулярных фракций гиалуроновой кислоты в глубокие слои кожи. В дерме под воздействием излучения лазера молекулы гиалуроновой кислоты восстанавливают исходную структуру и объем, что обеспечивает длительное увлажнение и регенерацию кожи с мощным лифтинговым эффектом. При этом, лазерная гиалуронопластика не вызывает аллергий и каких-либо осложнений, она не имеет противопоказаний и реабилитационного периода. А по результатам, которые видны уже после первых процедур, она значительно превосходит инъекционные методики.",
          additional: null,
          pictures: [{before:"giarulonaplastika_before.jpg", after: "giarulonaplastika_after.jpg"}],
          recovery: null,
          course: "3-6 процедур",
          effect_length: null,
          price: null,
          priceArea: [
              {
              area: "Лицо и шея",
              price: "12000р"
             },
              {
                  area: "Декольте",
                  price: "от 6000р"
              },
              {
                  area: "Кисти рук",
                  price: "4500р"
              },
              {
                  area: "Живот",
                  price: "от 10000р"
              },
              {
                  area: "Внутреняя поверхность бедер",
                  price: "от 10000р"
              },
              {
                  area: "Область промежности",
                  price: "от 4000р"
              }]
      },
      ft_laser_frac_rejuvination: {
          url: "ft_laser_frac_rejuvination",
          title: "Лазерное омоложение",
          cover: cover,
          used_for: ["Плотность и пористость кожи",
                     "Неровный рельеф кожи",
                     "«Гусиные лапки»",
                     "Тусклый цвет лица"],
          description: "Это инновационный, эффективный и безопасный метод лазерного омоложения, направленный на мощную стимуляцию омолаживающих процессов в глубоких слоях дермы. FT фракционное омоложение подходит как для увядающей, так и для молодой кожи и позволяет добиться непревзойденных результатов обновления и подтяжки кожи.br" +
              "Процедуру фракционного омоложения можно эффективно проводить на коже любого типа и получать желаемые результаты даже в летний период времени.",
          additional: "Суть процедуры фракционного омоложения заключается в следующем: лазерный луч пропускается через специальную фракционную решетку, которая разделяет его на множество микролучей. В результате происходит точечное воздействие на дерму. Клетки, окружающие участки микроповреждения, оказываются в состоянии теплового шока, начинают активно делиться и участвовать в восстановлении участков микроповреждения. Защитные силы организма (клеточный иммунитет) направляются на создание новых сосудов, на ускоренный синтез коллагена и эластина, одним словом, происходит мощная стимуляция омолаживающих процессов в глубоких слоях дермы. В последствие происходит стягивание мест повреждения, что ведет к быстрому сокращению площади кожного лоскута, исчезновению морщин. Выраженный эффект омоложения и лифтинга кожи сочетаются с быстрым заживлением поврежденных участков.br" +
              "В результате фракционного омоложения происходит формирование нового коллагенового каркаса кожи, запускаются омолаживающие процессы в глубоких слоях дермы, внешне это проявляется в повышении эластичности кожи, сужении пор, выравнивании текстуры и цвета кожи, разглаживании морщин, рубцов и растяжек.",
          pictures:[{before:"ft_shlifovka_before.jpg", after:"ft_shlifovka_after.jpg"}],
          recovery: null,
          course: "состоит из 2-3 процедур с интервалом в месяц",
          effect_length: "до трех лет",
          price:"-",
          priceArea: null
      },

      countur_plastic: {
          url: "countur_plastic",
          title: "Контурная пластика",
          cover: cover,
          used_for: [],
          description: "Kонтурная пластика – процедура введения под кожу препаратов Рестилайн, Перлайн и других филлеров в малых дозах. Инъекции гелей позволяют создать под кожей необходимый объем, за счет чего возможно увеличить губы, устранить морщины, заполнить носослезную борозду, носогубные складки, изменить контуры рта, скул, подбородка.",
          additional: "Быстрое проведение процедур. Контурная пластика с использованием препаратов на основе гиалуроновой кислоты занимает 15-30 минут. Эффект от инъекции виден сразу.br" +
              "Безопасность препаратов. Процедуры с использованием биодеградируемых препаратов являются одними из самых безопасных, так как они не нарушают естественные процессы в тканях.",
          pictures: [{before: "countur_plastic_before.jpg", after: "countur_plastic_after.jpg"}],
          recovery: null,
          course: "Индивидуально",
          effect_length: "До 2 лет",
          price: null,
          priceArea: [
              {
                  area: "Рестилайн 1,0",
                  price: "15500р"
              },
              {
                  area: "Рестилайн 0,5",
                  price: "10520р"
              },
              {
                  area: "Рестилайн Vital",
                  price: "12000р"
              },
              {
                  area: "Рестилайн Vital Injector 2.0",
                  price: "18000р"
              },
              {
                  area: "Рестилайн Vital Light",
                  price: "10500р"
              },
              {
                  area: "Рестилайн Перлайн 1,0",
                  price: "19800р"
              },
              {
                  area: "IAL-SYSTEM 1,1",
                  price: "7000р"
              }
          ]
      },

      smooth_rejuvination: {
          url: "smooth_rejuvination",
          title: "Smooth омоложение",
          cover: cover,
          used_for: ["Тусклый цвет кожи",
          "Мелкие и средние морщины",
          "Пористость кожи",
          "«Гусиные лапки»",
          "Нечеткий овал лица"],
          description: "Это лазерная экспресс-процедура с минимальным периодом восстановления, позволяющая получать немедленный выраженный омолаживающий эффект. В результате процедуры поверхностного лазерного омоложения происходит уменьшение площади кожного лоскута, благодаря чему кожа выглядит более подтянутой, мелкие морщины разглаживаются, улучшается внешний вид кожи, она становится гладкой и эластичной. Эффект после омоложения лазером сохраняется до полугода.",
          additional: "В основе процедуры поверхностного FT лазерного омоложениялежит воздействие лазерного луча на поверхностные слои кожи, в результате которого происходит сокращение коллагеновых и эластиновых волокон. Временный отек, возникающий из-за прогрева кожных покровов, усиливает эффект лифтинга кожи.",
          pictures: [{before:"smooth_before.jpg", after:"smooth_after.jpg"}],
          recovery: "3-5 дней",
          course: "Можно повторять каждые 3-6 месяцев",
          effect_length: "До полугода",
          price: "15000р",
          priceArea: null
      },







};

export default service_list;
